.switch {
  display: flex;
  background: #f8f8f8;
  width: max-content;
  padding: 7px 10px;
  position: relative;
  border-radius: 5px;
  margin: 0 10px;
  overflow: hidden;
  font-size: 12px;
  .white {
    color: white;
  }
  div {
    text-align: center;
    cursor: pointer;
    text-transform: uppercase;
    
  }
  div:nth-child(1) {
    position: relative;
    padding-right: 5px;
  
  }
  div:nth-child(2) {
    position: relative;
    padding-left: 5px;
   
  }
  &::before {
    content: "";
    height: 100%;
    background: #3c7ad5;
    position: absolute;
    top: 0;
    left: 0;
    transition: ease-out 0.3s;
  }
}

.no {
  &::before {
    transform: translateX(100%);
  }
}
@media(min-width:769px){
  .switch{
    div{
      width: 30px;
    }
    &::before{
      width: 45px;
    }
  }
}
@media(max-width:768px){
  .switch{
    div{
      width: 20px;
    }
    &::before{
      width: 36px;
    }
  }
}