@import "./styles/header.css";

.App{
    max-width: 100vw;
    min-height: 100vh;
    font-family: 'Roboto', sans-serif;
    /* overflow: hidden; */
    display: grid;
    grid-template-rows: auto 1fr;
}
.App .myConfetti {
    height: 100vh;
    width: 100vw;
    position: absolute !important;
    top: 0;
    left: 50%;
    /* overflow: hidden; */
  }
.body{
    background: #f8f8f8;
    display: grid;
    width: min(1180px, 100%);
}
 .content{
   
     position: relative;
    background: white;
   height: max-content;
}

.blue{
    color: #3b4964;
}
.order-summary{
    font-size: 20px;
    font-weight: 500;
}
.order-summary + .info{
    margin-top: 30px;
}
.info{
    font-size: 12px;
}
.pack_item:first-of-type{

    border-top:  1px solid #e8e9ed;

}
.main-subs{
    
    margin:  20px 0;
}
.pack_item{
  
    border-bottom:  1px solid #e8e9ed;
    padding: 20px 0;
}
.main-subs div{
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: 500;
}
.main-subs div span:nth-child(1){
    font-size: 18px;
}
.main-subs div span:nth-child(2){
    font-size: 14px;
}
.fee{
    font-size: 14px;
}
.space{
    display: flex;
    justify-content: space-between;
    padding-top: 18px;
    font-weight: 600;
}
.scope{
    font-size: 12px;
    margin-bottom: 30px;
}
.have-account{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px 0;
    font-size: 14px;
    color: #495d76;
}
.have-account button{
    margin-left: 10px;
}
@media(min-width : 769px){
    .body{
        padding: 20px calc((100% - 1180px)/2);
        grid-template-columns: 58% auto;
        grid-column-gap: 2%;
    }
    .body .side{
        padding: 0 10px;
    }
}
@media(max-width : 768px){
    .order-summary{
        margin-top: 20px;
    }
    .body{
        padding: 10px;
        grid-template-rows: auto auto;
        width: calc(100% - 20px);
    }
}
.error {
    display: flex;
    align-items: center;
    background: #f2dede;
    color: #a94442;
    margin: 20px 0 10px 0;
    padding: 10px 15px;
    border: 1px solid #ebccd1;
    border-radius: 5px;
    font-size: 14px;
   
  }
  .error  img {
    height: 18px;
    width: auto;
    margin-right: 10px;
  }
.loader{
    position: fixed;
    z-index: 10;   
}
.loader .MuiBackdrop-root{
    background: rgba(0,0, 0, 0.2) ;
}
.loader .MuiBackdrop-root .MuiCircularProgress-circle{

   stroke: #64a7ed!important;
}

.MuiTextField-root .MuiInputLabel-shrink{
    color: #64a7ed !important
}
.MuiInput-underline.Mui-error:after {
    border-bottom-color: #f44336 !important;
}
.MuiFormLabel-root.Mui-error {
    color: #f44336 !important;
}
.MuiTextField-root svg {
    fill: #a2a2a2;
}
.MuiTextField-root .Mui-focused {
    color: #495d76 ;
}
.MuiInput-underline::before{
    border-bottom:  1px solid #e3e3e3!important;
}
.MuiInput-underline::after{
    border-bottom:  2px solid #64a7ed!important;
}
.MuiInputLabel-root{
    color: #a2a2a2 !important;
    font-size: 14px !important;
}
.grecaptcha-badge{
    visibility: hidden;
  }