$brand-blue: #45a9f2;

@media (min-width: 1025px) {
  .content-wrapper{
    display: grid;
    grid-template-columns: auto 1fr;
    align-items: center;
  }
  .shuffle-container{
    height: 140px;
  }
  .selectComp {
    margin: 0 40px;
   .shuffle{
     height: 70px;

   }
    .checkbox {
      height: 20px;
      width: 20px;
    }
    .texts {
      font-size: 18px;
    }
  }
}
@media (min-width: 650px) and (max-width: 1024px) {
  .content-wrapper{
    display: grid;
    grid-template-columns: auto 1fr;
    align-items: center;
  }
  .selectComp {
    margin : 0 40px;
    .shuffle{
      height: 45px;
    }
    .checkbox {
      height: 18px;
      width: 18px;
    }
  }
  .texts{
    font-size: 14px;
  }
 
}
@media (max-width: 649px) {
  .content-wrapper{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px 0;
  }

  .selectComp {
    margin: 0 20px;
    padding: 10px 0;
    .shuffle-container {
      padding: 6% 7% !important;
    }
    .texts {
      font-size: 12px;
      span {
        margin: 0 2px;
      }
    }
    .checkbox {
      height: 16px;
      width: 16px;
    }
  }
  .shuffle{
    height: 40px;
    padding: 5px 10px;
  }
}
.selectComp + .selectComp{
  border-top: 2px dashed #eeeff3;
}
.selectComp {
  display: grid;
  grid-template-columns: auto 1fr;
  align-items: center;
  cursor: pointer;
  padding: 0px 20px;
  
  .price{
    margin-right:  5px;
  }
  .non-active {

    border: 4px solid transparent;
  }
  .circle-active{
    border: 2px solid $brand-blue;
    path {
      transition: ease-out 2s;
      animation: stroke 0.7s;
      fill: $brand-blue;
      
    }
  }
  .active {
    border: 4px solid $brand-blue;
    
    &--check {
      background: $brand-blue;
      height: 12px;
      width: 12px;
      padding: 7px;
      border-radius: 50%;
      position: absolute;
      top: -12px;
      right: -12px;
      animation: checkPop 0.6s;
      display: flex;
      align-items: center;
      img {
        height: auto;
        width: 100%;
      }
    }
  }
  .shuffle-container {
    background: #f7f7fa;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    padding: 2% 7%;
    transform: scale(0.6);
    position: relative;
    min-height: 100px;
    min-width: 140px;
    
    .none-check {
      display: none;
 
      img {
        display: none;
      }
    }
  }
  .texts {
    div {
      color: #495c75;
    }
  }
}

.checkbox {
  margin: auto;

  border: 1px solid $brand-blue;
  border-radius: 50%;
  svg{
    height: 80%;
  }
  path {
    // stroke-dasharray: 0 200;
    stroke-width: 1px;
    stroke: $brand-blue;
    fill: transparent;
    // stroke-dashoffset: 0;
  }
}
.shuffle {
  
  path:nth-child(1) {
    fill: #3c7ad5;
  }
  path:nth-child(2) {
    fill: #20c1df;
  }
  path:nth-child(3) {
    fill: #4dc0a9;
  }
  path:nth-child(4) {
    fill: #e99d23;
  }
  path:nth-child(5) {
    fill: #f47249;
  }
}
@keyframes checkPop {
  0% {
    transform: scale(0);
  }
  25% {
    transform: scale(1.4);
  }
  50% {
    transform: scale(0.8);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes stroke {
  0% {
    stroke-dashoffset: 41;
    stroke-dasharray: 40;
  }
  100% {
    stroke-dashoffset: 0;
    stroke-dasharray: 40;
    fill: $brand-blue;
  }
}
.none {
  border: 2px solid #45a9f2;
  path {
    stroke-dasharray: 40;
    stroke-dashoffset: 40;
  }
}
