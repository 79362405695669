$haha : 13px;

.page2{
    .title{
        color: #4e5d74;
        font-weight: 600;
        font-size: 20px;
        padding: 20px 0;
    }
   .invite{
       display: flex;
       align-items: center;
       color: #4e5d74;
       font-size: 14px;
       margin: 30px 0 15px 0;
   }
}
@media(min-width: 769px){
    .page2{
        width: calc(100% - 80px);
        padding: 20px 40px 40px 40px;
        .wrapper{
            display: grid;
            grid-template-columns: 1fr 1fr;
            grid-column-gap: 5px;
            margin: 30px 0;
        }
    }
}
@media(max-width: 768px){
    .page2{
        width: calc(100% - 40px);
        padding: 20px 20px 40px 20px;
        .wrapper{
            display: grid;
            grid-template-rows: auto auto;
            grid-row-gap: 20px;
            // margin-top: 20px;
        }
        .MuiFormControl-fullWidth+ .wrapper {
            margin-top: 20px;
        } 
        .wrapper+ .MuiFormControl-fullWidth{
            margin-top: 20px;
        } 
    }
}