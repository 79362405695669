.header{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: auto;
    width: min(500px , 80vw);
    padding: min(20px, 5vw) 0;

    
}
.tasks{
    position: relative;
}
.tasks .checks{
    position: absolute;
    width:  100%;
    height: 100%;
    display: grid;
    align-items: center;
    grid-template-columns: 10% 10% 10% 10%;
    grid-column-gap: 20%;
}
.tasks .checks-three{
    position: absolute;
    width: 100%;
    height: 100%;
    display: grid;
    align-items: center;
    grid-template-columns: 10% 10% 10%;
    grid-column-gap: 20%;
    justify-content: center;
}
.checks-three img{
    transform: scale(0.4);
    width: 100%;
    animation: pop 0.5s;
}

.checks img{
    transform: scale(0.4);
    width: 100%;
    animation: pop 0.5s;
}
.elify-logo{
    height: min(60px, 15vw);
}
.header .tasks{
    margin-top: 15px;
    width: min(420px, 100%);
   
}
.header .stages{
    width: calc(min(420px, 100%) + 20px);
    display: grid;
    grid-template-columns: 16% 16% 16% 16%;
    grid-column-gap: 12%;
    justify-content: center;

}.header .stages-three{
    width: calc(min(420px, 100%) + 20px);
    display: grid;
    grid-template-columns: 16% 16% 16%;
    grid-column-gap: 12%;
    justify-content: center;
}
.header .stages-three div{
    text-transform: uppercase;
    display: flex;
    justify-content: center;
    font-weight: 500;
    padding-top: 5px;
    font-size: 10px;
    text-align: center;
}
.header .stages-three .active{
    color: #64a7ed;
}
.header .stages div{
    text-transform: uppercase;
    display: flex;
    justify-content: center;
    font-weight: 500;
    padding-top: 5px;
    font-size: 10px;
    text-align: center;
}
.header .stages .active{
    color: #64a7ed;
}

@keyframes pop {
    0% {
      transform: scale(0);
    }
    25% {
      transform: scale(0.8);
    }
    50% {
      transform: scale(0.3);
    }
    100% {
      transform: scale(0.4);
    }
  }