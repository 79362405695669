.page3{
    padding: 20px 40px 40px 40px;
    color: #4e5d74;
    .methods{
        display: flex;
        align-items: center;
        .credit-card{
            font-size: 14px;
            text-decoration: underline;
            cursor: pointer;
        }
    }
    .method-changer{
        cursor: pointer;
        text-decoration: underline;
    }
    .wrapper{
        align-items: flex-end;
        margin: 30px 0;
    }
    .form-item{
        label{
            color: #919191 ; 
            font-size: 12px;
        }
        .hosted-field{
            height: 32px;
            border-bottom: 1px solid #dadada;


            
        }
    }
    .title{
        font-size: 20px;
        padding: 20px 0;
        font-weight: 600;
    }
    .payment-method{
        text-transform: capitalize;
        font-style: italic;
        font-size: 14px;
        margin: 12px 0;
    }
    .or{
        font-style: italic;
        font-size: 14px;
        margin: 0 10px;
    }
}
@media(min-width: 769px){
    .page3{
        .wrapper{
            display: grid;
            grid-template-columns: 1fr 1fr;
            grid-column-gap: 10px;
        }
        .wrapper-triple{
            display: grid;
            grid-template-columns: 1fr auto auto;
            grid-column-gap: 10px;
        }
    }
}
@media(max-width: 768px){
    .page3{
        .wrapper{
            display: grid;
            grid-template-rows: auto auto;
            grid-row-gap: 30px;
        }
        .wrapper-triple{
            display: grid;
            grid-template-rows: auto auto;
            grid-row-gap: 30px;
        }
    }
}