.page4{
    background: white;
   
    .title{
        color: #4e5d74;
        font-size: 20px;
        font-weight: 600;
        padding: 20px 0;
    }
    .grid-one{
        padding-bottom: 20px;
    }
    .line-one{
        display: flex;
        align-items: center;
    }
    .button-container{
        display: flex;
        justify-content: flex-end;
        padding: 40px 0 20px 0;
    }
}
.one-wrap{
    height: calc(100% - 20px);
display: flex;
justify-content: center;
position: relative;
overflow: hidden;
background: #f8f8f8;
.content{
    width: clamp(320px, 60%, 600px);
}
}
@media(min-width: 769px){
    .page4{
        padding: 20px 40px;
        a{
            color: #65a8ec;
        }
        .italic{
            font-size: 14px;
        }
        .grid-two{
            margin: 20px 0;
            display: grid;
            grid-template-columns: 1fr 1fr;
            grid-column-gap: 10px;
        }
        .line-one{
            font-size: 14px;
        }
    }
    .one-wrap{
        padding: 40px 20px;
    }
}
@media(max-width: 768px){
    .one-wrap{
        padding: 20px 10px;
    }
    .page4{
        padding: 20px ;
        .grid-two{
            margin: 20px 0;
            display: grid;
            grid-template-rows: 1fr 1fr;
            grid-row-gap: 30px;
        }
        .italic{
            font-size: 12px;
        }
        .line-one{
            font-size: 12px;
        }
    }
}