.main-congrats {
  position: relative;
  background: white;
  padding: 20px;
  margin: auto;
}
.arrow-container{
  display: flex;
  justify-content: flex-end;

}
.congrats {
  background: white;
  box-shadow: -3px 3px 13px 1px rgba(0, 0, 0, 0.26);
  position: relative;
  z-index: 3;
  display: flex;
  flex-direction: column;
  color: #455a73;
  .title{
    color: white;
    font-weight: 600;
    margin: 30px 0;
    text-align: center;
    text-transform: uppercase;
  }
  .user {
    font-size: 20px;
    font-weight: 500;
    text-align: center;
    padding: 20px 0;
  }
  .text {
    text-align: center;
    font-size: 14px;
  }
  .emails {
    font-size: 14px;
    font-weight: 500;
    div:nth-child(1){
      padding-bottom: 20px;
    }
  }
  &--header {
    position: relative;
    overflow: hidden;
    background-position: center;
    background-size: contain;

    .pattern {
      position: absolute;
      height: 120%;
      width: auto;
      opacity: 0.5;
    }
    .two {
      left: -10%;
      top: -25%;
    }
    .one {
      right: -1%;
      top: 5%;
      transform: scale(1.2);
    }
    img {
      width: 100%;
    }
  }
  
  .buttons {
    display: grid;
  
    button {
     font-size: 12px;
      color: white;
      font-weight: 600;
    
    }
   
    button:nth-child(1) {
      background: #4dc0a9;
    }
    button:nth-child(2) {
      background: #5e5ad6;
    }
    button:nth-child(3) {
      background: #45a9f2;
    }
  }
  .note {
    text-align: center;
    
  }
 
  .third {
    div:nth-child(3) {
      margin-top: 20px;
    }
  }
}
@media (min-width: 650px) {
  .congrats {
   .note{
     font-size: 14px;
     padding: 20px 40px 0 40px 
   }
    margin: auto;
    .emails{
      padding: 0 40px 20px 40px;
    }
    .title {
      font-size: 25px;
    }
    .text{
      padding: 0 40px 20px 40px;
    }
    
  }
  .arrow-container{
    padding: 20px 40px;
  }
  .buttons{
    padding: 20px 40px;
    grid-template-columns:  1fr 1fr 1fr;
    grid-column-gap: 10px;
  }
}
@media (max-width: 649px) {
  .congrats {
    .note{
      font-size: 12px;
      padding: 20px 20px 0 20px 
    }
    margin: auto;
    .emails{
      padding: 0 20px 20px 20px;
    }
    .title{
      font-size: 18px;
    }
    .text{
      padding: 0 10px 20px 10px;
    }
  }
  .arrow-container{
    padding: 20px 10px;
  }
  .buttons{
    padding: 20px;
    grid-template-rows:  1fr 1fr 1fr;
    grid-row-gap: 10px;
  }
}
