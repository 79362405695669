.page1{
    .container{
        
        color: #495d76;
        .text{
            padding: 10px 0;
            font-size: 15px;
        }
    }
    .free-container{
        background: #f8f8f8;
        text-align: center;
    }
}
.welcome{
    font-size: 20px;
    font-weight: 600;
    padding: 20px 0 ;
}
.hundred_cards{
    display: flex;
    flex-direction: column;
    align-items: center;
}
.the-hundred{
    font-size: 14px;
}
.the-hundred{
    font-size: 48px;
    font-weight: 600;
}
.try-free{
    text-decoration: underline;
    font-size: 13px;
    padding: 10px 30px 30px 30px;
    color: rgb(59,122,213);
    cursor: pointer;
}

@media(min-width: 769px){
    .page1 .container{
        padding: 20px 40px 0 40px;
    }
  
}
@media(max-width: 768px){
    .page1 .container{
        padding: 20px 20px 0 20px;
    }
   
}